$(() => {
    const $youtube = app.DOM.content.find('#modal-youtube');
    const $iframe = $youtube.find('iframe');
    const $help_videos = app.DOM.content.find('.help-videos');
    const $modalTitle = app.DOM.content.find('#modal-consent-title');

    $help_videos.find('.column-video').on('click', e =>{

        let $el = $(e.target);
        let yt = $el.attr('data');

        $iframe.attr('src', `https://www.youtube.com/embed/${yt}?&autoplay=1`);
        $modalTitle.text($el.find('.column-title').text());

        MicroModal.show('modal-youtube', {
            onClose: function(){
                // turn off playing video if playing
                $iframe.attr('src', '');
            }
        });
    });

    $.each($help_videos, (k,v) => {

        let $filter = $(v).prev().find('.help-filter-input');
        let $columns = $(v).find('.column-video');
        // let $columnsBlank = $(v).find('.column-spacer');

        $filter.on('keyup', e => {


            let val = e.target.value.toLowerCase();

            if( val ){

                // $columnsBlank.hide();
                $columns.hide();

                $.each($columns, (k,v) => {
                    let title = $(v).attr('data-title').toLowerCase();

                    if( title.indexOf(val) >= 0 ) {
                        $(v).show();
                    }
                });
            } else {
                $columns.show();
                // $columnsBlank.show();
            }
        });
    });

    app.DOM.form_tab_a.on('click clickInit', e => {

        setTimeout( () => {

            app.DOM.tabs.filter(':visible').find(':input').focus();

        }, 100);

    }).eq(0).trigger('clickInit');
});